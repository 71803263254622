export const NETWORK = {
  TARGET_CHAIN_ID: 1,
  MAINNET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    42: "kovan",
    1337: "localhost",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
};

export const CONTRACTS = {
  MINTER: "FlowerFamMinter",
  MINTPASS: "FlowerFamMintPass",
};

export const ROUND_MAPPING = {
  0: "Flower + Honey List",
  1: "Giveaway List",
  2: "Raffle List",
  3: "Public Mint",
};
