/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { isEmpty } from "_dash";
import { getProofs } from "utils/merkle_proof";
import { getContractConfig } from "utils/contract";
import { ethToRegularString, getTxUrl } from "utils";
import { CONTRACTS, NETWORK, ROUND_MAPPING } from "global_constants";

import Faq from "components/faq";
import Minter from "adpaters/minter";
import Button from "components/button";
import ProviderHOC from "hoc/provider";
import MintPass from "adpaters/mintPass";
import useConnect from "hooks/useConnect";
import useProvider from "hooks/useProvider";
import AlertModal from "components/alertModal";

import cloudSvg from "assets/graphics/Clouds_1.svg";
import cloudBackSvg from "assets/graphics/Group-105.svg";
import flowerFamGif from "assets/graphics/FlowerFam—gifs.gif";
import { ReactComponent as LogoSvg } from "assets/graphics/Logo.svg";

import "./index.css";

const App = ({ provider, setProvider }) => {
  const contracts = useProvider(provider);
  const [isConnected, , doConnect, connection] = useConnect(provider);

  const [txHash, setTxHash] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [minting, setMinting] = useState(false);

  const [price, setPrice] = useState();
  const [mintCount, setMintCount] = useState(1);
  const [supplyCount, setSupplyCount] = useState(6969);
  const [currentRound, setCurrentRound] = useState(-1);
  const [currentRoundLimit, setCurrentRoundLimit] = useState(0);

  useEffect(() => {
    // doAutoConnect();
  }, []);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
  }, [connection]);

  function initialize(contract) {
    const mintObj = new Minter(contract);

    mintObj.getSupplyLeft().then((supply) => {
      setSupplyCount(supply.toNumber());
    });

    mintObj.getPrice().then((price) => {
      setPrice(price);
    });

    mintObj
      .getActiveRound()
      .then((round) => {
        setCurrentRound(round.toNumber());
        mintObj.roundToMintLimits(round).then((limit) => {
          setCurrentRoundLimit(limit);
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  useEffect(() => {
    const config = getContractConfig(
      NETWORK.TARGET_CHAIN_ID.toString(),
      CONTRACTS.MINTER
    );
    if (config) {
      const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_RPC_URL
      );
      const contract = new ethers.Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        config.abi,
        provider
      );
      initialize(contract);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      provider.instance.on("block", () => {
        initialize(contracts[CONTRACTS.MINTER]);
      });
      return () => provider.instance.off("block");
    }
  }, [contracts]);

  function onIncrease() {
    if (mintCount < supplyCount) {
      setMintCount((_) => _ + 1);
    }
  }

  function onDecrease() {
    if (mintCount > 1) {
      setMintCount((_) => _ - 1);
    }
  }

  async function onMint(doStake = false) {
    if (currentRound > -1) {
      setMinting(true);
      const mintObj = new Minter(contracts[CONTRACTS.MINTER], provider.address);
      const supplyLeft = await mintObj.getSupplyLeft();
      if (mintCount <= supplyLeft) {
        const userSupplyLeft = parseInt(
          await mintObj.getUserMintSupplyLeft(currentRound)
        );
        if (currentRound === 0 || mintCount <= userSupplyLeft) {
          let tx = null;
          const extraData = {
            value: ethers.utils.parseEther("0.069").mul(mintCount),
          };
          if (currentRound === 3) {
            tx = await mintObj.publicMint(
              provider.signer,
              mintCount,
              doStake,
              extraData
            );
          } else {
            const proofs = getProofs(
              currentRound,
              provider.address.toLowerCase()
            );
            if (!proofs || !proofs.length) {
              setErrorMsg(
                "You are not eligible to mint this round. Please come back later."
              );
            } else {
              if (currentRound === 1) {
                tx = await mintObj.giveawayMint(
                  provider.signer,
                  mintCount,
                  proofs,
                  doStake,
                  extraData
                );
              } else if (currentRound === 2) {
                tx = await mintObj.raffleMint(
                  provider.signer,
                  mintCount,
                  proofs,
                  doStake,
                  extraData
                );
              } else {
                const mintPassObj = new MintPass(
                  contracts[CONTRACTS.MINTPASS],
                  provider.address
                );

                const passLeftCount = parseInt(
                  await mintPassObj.userLeftPasses()
                );

                if ((!proofs || !proofs.length) && passLeftCount === 0) {
                  setErrorMsg(
                    "You are not eligible to mint this round. Please come back later."
                  );
                } else {
                  if (
                    mintCount >
                    (!proofs || !proofs.length
                      ? passLeftCount
                      : userSupplyLeft + passLeftCount)
                  ) {
                    setErrorMsg(
                      "You exceed the maximum mint limit of this round"
                    );
                  } else {
                    tx = await mintObj.whitelistMint(
                      provider.signer,
                      mintCount,
                      proofs,
                      doStake,
                      extraData
                    );
                  }
                }
              }
            }
          }
          if (tx) {
            setMintCount(1);
            setTxHash(tx.hash);
            await tx.wait();
          }
        } else {
          setErrorMsg("You exceed the maximum mint limit of this round");
        }
      } else {
        setErrorMsg(
          "The supply of the current round is sold out. Please come back later for the next round."
        );
      }
    } else {
      setErrorMsg("Minting starts May 21st 6PM CEST.");
    }
    setMinting(false);
    setTxHash(undefined);
  }

  return (
    <>
      <div className="h-full">
        <div
          className="mt-12"
          style={{ backgroundImage: `url(${cloudBackSvg})` }}
        >
          <LogoSvg className="m-auto" />
          <div className="container m-auto mt-12">
            <div className="text-center">
              <p className="font-arco text-7xl title-stroke text-white">
                mint your
              </p>
              <p className="font-arco text-7xl title-stroke text-white">
                <span className="text-[#ffec3e]">flower fam</span> NFT
              </p>
            </div>
            <div className="flex gap-12 mt-20 justify-center p-4 flex-col md:flex-row md:p-8">
              <img
                alt="FlowerFam"
                src={flowerFamGif}
                className="md:h-[490px] rounded-[20px]"
              />
              <div className="bg-white p-6 md:p-8 flex-1 max-w-[500px] pr-2 rounded-[40px]">
                <div className="text-textColor font-morh-sb text-lg">
                  <p>Are you lucky enough to</p>
                  <p>
                    mint{" "}
                    <span className="font-morh-b">1 of our 15 pioneers?</span>
                  </p>
                </div>
                <div className="text-textColor font-morh-sb text-lg mt-4">
                  {currentRound === -1 ? (
                    "Minting starts at May 21st 6PM CEST"
                  ) : (
                    <>
                      <p>
                        Active round:{" "}
                        <span className="font-morh-b">
                          {ROUND_MAPPING[currentRound]}
                        </span>
                      </p>
                      <p>
                        Mint limit per user:{" "}
                        <span className="font-morh-b">
                          {currentRoundLimit.toString()}
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="text-textColor font-morh-sb text-lg mt-4">
                  <p>
                    Flower List + Honey List ={" "}
                    <span className="font-morh-b">2</span>{" "}
                  </p>
                  <p>
                    Giveaway Winners = <span className="font-morh-b">1</span>{" "}
                  </p>
                  <p>
                    Raffle = <span className="font-morh-b">2</span>
                  </p>
                </div>
                <div className="flex mt-6 gap-6">
                  <div>
                    <p className="font-morh-sb mb-2">Supply</p>
                    <p className="bg-gray-100 py-2 px-4 text-2xl text-textColor font-bold rounded-lg">
                      {supplyCount}
                    </p>
                  </div>
                  <div>
                    <p className="font-morh-sb mb-2">Price</p>
                    <p className="bg-red-100 text-red-600 py-2 px-4 text-2xl font-bold rounded-lg">
                      {(price &&
                        (ethToRegularString(price) * mintCount).toFixed(3)) ||
                        0.069}{" "}
                      ETH
                    </p>
                  </div>
                </div>
                {isConnected ? (
                  <>
                    <div className="flex mt-6 gap-6">
                      <div className="flex items-center border-gray-200 border-2 rounded-full gap-4 px-4">
                        <p
                          onClick={() => onDecrease()}
                          className="text-3xl cursor-pointer"
                        >
                          -
                        </p>
                        <p className="font-morh-sb text-3xl mt-[7px]">
                          {mintCount}
                        </p>
                        <p
                          onClick={() => onIncrease()}
                          className="text-3xl cursor-pointer"
                        >
                          +
                        </p>
                      </div>
                      <Button
                        isPrimary
                        loading={minting}
                        onClick={() => onMint()}
                        classes={[
                          "text-lg",
                          "uppercase",
                          "font-morh-b",
                          "text-center",
                        ]}
                      >
                        Mint my flower
                      </Button>
                    </div>
                    <div className="relative flex pt-4 mb-3 items-center">
                      <div className="flex-grow border-t-2 border-gray-200 border-dashed" />
                      <span className="flex-shrink mx-4 text-textColor font-morh-b text-lg">
                        or
                      </span>
                      <div className="flex-grow border-t-2 border-gray-200 border-dashed" />
                    </div>
                    <div className="flex gap-6">
                      <div className="flex items-center border-gray-200 border-2 rounded-full gap-4 px-4">
                        <p
                          onClick={() => onDecrease()}
                          className="text-3xl cursor-pointer"
                        >
                          -
                        </p>
                        <p className="font-morh-sb text-3xl mt-[7px]">
                          {mintCount}
                        </p>
                        <p
                          onClick={() => onIncrease()}
                          className="text-3xl cursor-pointer"
                        >
                          +
                        </p>
                      </div>
                      <Button
                        isPrimary
                        loading={minting}
                        onClick={() => onMint(true)}
                        classes={[
                          "text-lg",
                          "uppercase",
                          "font-morh-b",
                          "text-center",
                        ]}
                      >
                        Mint & stake my flower
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    isPrimary
                    onClick={() => doConnect()}
                    classes={[
                      "mt-8",
                      "uppercase",
                      "!bg-[#ffec3e]",
                      "text-textColor",
                    ]}
                  >
                    Connect wallet
                  </Button>
                )}
                {txHash ? (
                  <div className="mt-8 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={getTxUrl(txHash)}
                      className="font-morh-sb text-xl"
                    >
                      View TX
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <p className="mt-4 text-sm text-gray-400">*double staking rewards in the first 12 hours</p>
              </div>
            </div>
          </div>
        </div>
        <img src={cloudSvg} alt="Clouds" width="100%" />
        <Faq />
      </div>
      <AlertModal
        message={errorMsg}
        visible={!!errorMsg}
        onClose={() => setErrorMsg()}
      />
    </>
  );
};

export default ProviderHOC(App);
