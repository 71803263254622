export default class MintPass {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  userLeftPasses() {
    return this.instance.userPassesLeft(this.address);
  }
}
