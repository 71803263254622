export default class Minter {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getPrice() {
    return this.instance.price();
  }

  getSupplyLeft() {
    return this.instance.getSupplyLeft();
  }

  getActiveRound() {
    return this.instance.getActiveRound();
  }

  getMintSupplyLeft(round) {
    return this.instance.getMintedAtRound(round);
  }

  getUserMintSupplyLeft(round) {
    return this.instance.getUserMintedAtRound(this.address, round);
  }

  whitelistMint(signer, amount, proofs, doStake, extraData) {
    return this.instance
      .connect(signer)
      .whitelistMint(amount, proofs, doStake, extraData);
  }

  giveawayMint(signer, amount, proofs, doStake, extraData) {
    return this.instance
      .connect(signer)
      .giveawayMint(amount, proofs, doStake, extraData);
  }

  raffleMint(signer, amount, proofs, doStake, extraData) {
    return this.instance
      .connect(signer)
      .raffleMint(amount, proofs, doStake, extraData);
  }

  publicMint(signer, amount, doStake, extraData) {
    return this.instance.connect(signer).publicMint(amount, doStake, extraData);
  }

  roundToMintLimits(round) {
    return this.instance.roundToMintLimits(round);
  }
}
