import Raffle from "proofs/raffleRound.json";
import Giveaway from "proofs/giveawayRound.json";
import Whitelist from "proofs/whitelistRound.json";

export const getProofs = (round, address) => {
  let proofs = [];
  if (round === 0) {
    proofs = Whitelist.proofs[address];
  }
  if (round === 1) {
    proofs = Giveaway.proofs[address];
  }
  if (round === 2) {
    proofs = Raffle.proofs[address];
  }
  return (proofs || []).length ? proofs[0] : [];
};
