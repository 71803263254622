import { useState } from "react";
import { ReactComponent as FaqIconSvg } from "assets/graphics/_.svg";

const FAQ_ITEMS = [
  {
    title: "Where can I find the Oasis?",
    content: (
      <a
        href="https://oasis.flowerfam.earth/"
        style={{ textDecoration: "underline" }}
      >
        Click here
      </a>
    ),
  },
  {
    title: "Wen can I mint?",
    content: (
      <p>
        Stage 1: Flower List + Honey List: 09:00 PT / 18:00 CEST / 00:00 CST
        <br />
        Stage 2: Giveaway Winners: 10:09 PT / 19:09 CEST / 01:09 CST
        <br />
        Stage 3: Raffle: 11:18 PT / 20:18 CEST / 02:18 CST
        <br />
        Stage 4: Public sale: time?
      </p>
    ),
  },
  {
    title: "How many Flowers can I mint?",
    content: (
      <p>
        Flower List = 2
        <br />
        Honey List = 2
        <br />
        Giveaway Winners = 1
        <br />
        Raffle = 2
      </p>
    ),
  },
  {
    title: "How do I know if I won the raffle?",
    content: (
      <p>
        Connect your wallet at 11:18 PT / 20:18 CEST / 02:18 CST to see if
        you’re eligible to mint.
      </p>
    ),
  },
  {
    title: "How does Honey List work?",
    content: (
      <p>
        Being on the Honey List gives you access to mint 2 NFTs in Stage 1.
        <br />
        If you’re a Giveaway Winner + Honey List, you must mint separately in
        Stage 1 and Stage 2.
        <br />
        If you are Flower List + Honey List, you can mint 4 in one transaction.
      </p>
    ),
  },
  {
    title: "How does mint+stake work?",
    content: (
      <p>
        You can stake and mint your flowers in one transaction. Just remember
        that your NFTs won’t appear in your wallet if you do so. Just head
        straight over to the Oasis to visit them there.
      </p>
    ),
  },
  {
    title: "Are gas fees higher if I stake+mint?",
    content: (
      <p>
        Yes, they are slightly higher. But do you have time and ETH to do it
        separately?
      </p>
    ),
  },
  {
    title: "Can I access the Oasis right after staking my Flowers?",
    content: <p>Yes, you can.</p>,
  },
  {
    title: "I bought $honeycoin, can I see this in the Oasis?",
    content: <p>Yes, you see your $honeycoin in your Oasis wallet.</p>,
  },
  {
    title:
      "I am on the FlowerList and/or HoneyList, so I can mint 2 NFTs. Can I first mint and stake later?",
    content: (
      <p>Yes. But you will pay gas fees twice. No one likes double gas fees.</p>
    ),
  },
  {
    title: "I missed out on my stage, can I still mint in a later stage?",
    content: <p>No. Fingers crossed that you are chosen in the raffle.</p>,
  },
  {
    title: "Who did the audit?",
    content: (
      <p>
        Our Frens over at{" "}
        <a href="https://blockapex.io/" style={{ textDecoration: "underline" }}>
          BlockApex
        </a>
      </p>
    ),
  },
  {
    title: "Wen reveal? ",
    content: <p>99 hours after mint.</p>,
  },
  {
    title: "Do I own the IP rights of my flower?",
    content: <p>Yes. Do whatever you want with it.</p>,
  },
  {
    title: "Wen Oasis live?",
    content: (
      <p>
        The Oasis is already growing and thriving. Check the official link in
        Discord.
      </p>
    ),
  },
  {
    title: "Did you say special rewards?",
    content: (
      <p>
        Double Staking rewards in the first 69 hours after mint.
        <br />
        We’re giving away 5 Bees, 69x batches of $honeycoin, and 10 New
        Generation Flowers for the first 690 minters
      </p>
    ),
  },
  {
    title: "Anything else?",
    content: <p>Gas fees for failed transactions will not be refunded.</p>,
  },
];

export default function Faq() {
  const [openFaqItems, setOpenFaqItems] = useState([]);

  function onToggleFaqItem(idx) {
    const index = openFaqItems.indexOf(idx);
    if (index > -1) {
      setOpenFaqItems(openFaqItems.filter((i) => i !== idx));
    } else {
      setOpenFaqItems([...openFaqItems, idx]);
    }
  }

  return (
    <div className="container m-auto pt-16 pb-24 px-4">
      <p className="font-arco text-7xl title-stroke text-[#ffec3e] text-center mb-12">
        faq
      </p>
      <div className="faq-wrapper max-w-[800px] m-auto">
        {FAQ_ITEMS.map((item, idx) => {
          const isOpen = openFaqItems.indexOf(idx) > -1;
          return (
            <div key={idx} className="faq-item bg-white rounded-3xl my-6">
              <div
                onClick={() => onToggleFaqItem(idx)}
                className="faq-title px-10 pt-6 pb-5 cursor-pointer flex justify-between"
              >
                <p className="font-morh-b text-xl text-textColor">
                  {item.title}
                </p>
                <FaqIconSvg
                  className={`toggle-faq-icon transition-all ${
                    isOpen ? "active" : ""
                  }`}
                />
              </div>
              <div
                className={`faq-content font-morh-m pb-6 px-10 text-textColor ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                {item.content}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
